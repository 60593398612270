
import { Landscape, ModelObject, ModelObjectTechnology } from '@icepanel/platform-api-client'
import Fuse from 'fuse.js'
import debounce from 'lodash/debounce'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Animation from '@/components/animation.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import * as sort from '@/helpers/sort'
import { iconUrlForTheme } from '@/helpers/theme'
import CatalogTechnologyMenu from '@/modules/catalog/components/technology/menu.vue'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import DescriptionEditor from '@/modules/editor/components/description-editor.vue'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import HistoryCompact from '@/modules/history/components/history-compact.vue'
import { LandscapeModule } from '@/modules/landscape/store'
import { OrganizationModule } from '@/modules/organization/store'
import { ShareModule } from '@/modules/share/store'
import TagDeleteDialog from '@/modules/tag/components/delete-dialog.vue'
import TagGroupDeleteDialog from '@/modules/tag/components/group/delete-dialog.vue'
import TagPicker from '@/modules/tag/components/tag-picker/index.vue'
import { TagModule } from '@/modules/tag/store'
import TeamPicker from '@/modules/team/components/picker.vue'
import { TeamModule } from '@/modules/team/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import ActionsMenu from '../components/actions-menu.vue'
import ConnectionDeleteDialog from '../components/connections/connection-delete-dialog.vue'
import DependencyCanvas from '../components/dependencies/canvas.vue'
import InDiagrams from '../components/in-diagrams.vue'
import InFlows from '../components/in-flows.vue'
import ObjectConnectionsList from '../components/object-connections-list/index.vue'
import ObjectDependenciesList from '../components/object-dependencies-list/index.vue'
import ObjectGroups from '../components/object-group/index.vue'
import ObjectSelectMenu from '../components/object-select-menu/index.vue'
import ObjectLinksList from '../components/objects/links-list/index.vue'
import ObjectCaption from '../components/objects/object-caption.vue'
import ObjectDeleteDialog from '../components/objects/object-delete-dialog.vue'
import ObjectEditableBy from '../components/objects/object-editable-by.vue'
import ObjectExternal from '../components/objects/object-external.vue'
import ObjectName from '../components/objects/object-name.vue'
import ObjectParent from '../components/objects/object-parent.vue'
import ObjectParentUpdateDialog from '../components/objects/object-parent-update-dialog.vue'
import ObjectPreviewList from '../components/objects/object-preview-list.vue'
import ObjectType from '../components/objects/object-type.vue'
import ObjectTypeUpdateDialog from '../components/objects/object-type-update-dialog.vue'
import Status from '../components/status.vue'
import TechnologyList from '../components/technology-list/index.vue'
import * as analytics from '../helpers/analytics'
import { directIncomingConnections, directOutgoingConnections, lowerIncomingConnections, lowerOutgoingConnections } from '../helpers/connections'
import { ModelModule } from '../store'

const FOCUSED_OBJECT_KEY = 'dependenciesFocusedObject'
const OBJECT_WIDTH = 260
const EDITING_INTERVAL = 10 * 1000

@Component({
  components: {
    ActionsMenu,
    Animation,
    CatalogTechnologyMenu,
    ConnectionDeleteDialog,
    DependencyCanvas,
    DescriptionEditor,
    HistoryCompact,
    InDiagrams,
    InFlows,
    ObjectCaption,
    ObjectConnectionsList,
    ObjectDeleteDialog,
    ObjectDependenciesList,
    ObjectEditableBy,
    ObjectExternal,
    ObjectGroups,
    ObjectLinksList,
    ObjectName,
    ObjectParent,
    ObjectParentUpdateDialog,
    ObjectPreviewList,
    ObjectSelectMenu,
    ObjectType,
    ObjectTypeUpdateDialog,
    Status,
    Tabs,
    TagDeleteDialog,
    TagGroupDeleteDialog,
    TagPicker,
    TeamPicker,
    TechnologyList
  },
  name: 'ModelDependencies'
})
export default class extends Vue {
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  searchIncoming = ''
  searchIncomingModel = ''
  searchIncomingFocused = false

  searchOutgoing = ''
  searchOutgoingModel = ''
  searchOutgoingFocused = false

  objectWidth = OBJECT_WIDTH

  editingNotificationTimer?: number

  keydownListener!: (e: KeyboardEvent) => void

  @Ref() readonly objectSelectMenuRef!: ObjectSelectMenu
  @Ref() readonly dependencyCanvasRef!: DependencyCanvas

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion?.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get objectTab () {
    return this.$queryValue('object_tab')
  }

  get currentObjectHandleIds () {
    return this.$queryArray('object')
  }

  get objectFocusedHandleId () {
    return this.$queryValue('object_focus')
  }

  get dependencyOriginHandleId () {
    return this.$queryValue('dependency_origin')
  }

  get dependencyTargetHandleId () {
    return this.$queryValue('dependency_target')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscapePermission () {
    return this.currentVersionId === 'latest' ? this.landscapeModule.landscapePermission(this.currentLandscape) : 'read'
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentDomain () {
    return Object.values(this.domainModule.domains).find(o => o.handleId === this.currentDomainHandleId)
  }

  get currentDomainObjects () {
    return Object.values(this.modelModule.objects).filter(o => o.type !== 'root' && (!this.currentDomain || o.domainId === this.currentDomain.id))
  }

  get focusedObject () {
    return this.currentDomainObjects.find(o => o.handleId === this.objectFocusedHandleId)
  }

  get dependencyOrigin () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.dependencyOriginHandleId)
  }

  get dependencyTarget () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.dependencyTargetHandleId)
  }

  get objects () {
    return this.modelModule.objects
  }

  get currentObject () {
    return this.currentObjects.length === 1 ? this.currentObjects[0] : undefined
  }

  get currentObjects () {
    return this.currentObjectHandleIds
      .map(o => {
        const modelObjectId = this.modelModule.objectHandles[o]
        return modelObjectId ? this.modelModule.objects[modelObjectId] : undefined
      })
      .filter((o): o is ModelObject => !!o)
  }

  get currentUserTyping () {
    return Object.values(this.editorModule.typing).find(o => o.id === this.currentObject?.id && o.userId !== this.userModule.user?.id)
  }

  get currentObjectLinkCount () {
    return Object.keys(this.currentObject?.links || {}).length
  }

  get objectLinksSyncStatus () {
    return this.modelModule.objectLinksSyncStatus
  }

  get currentObjectIds () {
    return this.currentObjects.map(o => o.id)
  }

  get currentModelTechnologies () {
    const technologies = this.currentObjects.reduce((p, c) => ({
      ...p,
      ...c.technologies
    }), {} as Record<string, ModelObjectTechnology>)
    return Object
      .values(technologies)
      .map(o => ({
        ...o,
        icon: iconUrlForTheme(o)
      }))
      .sort(sort.index)
  }

  get objectTabs () {
    const tabs: ITab[] = [
      {
        id: 'details',
        text: 'Details',
        to: {
          query: this.$setQuery({
            object_tab: 'details'
          })
        }
      },
      {
        id: 'connections',
        text: 'Connections',
        to: {
          query: this.$setQuery({
            object_tab: 'connections'
          })
        }
      },
      {
        id: 'history',
        text: 'History',
        to: {
          query: this.$setQuery({
            object_tab: 'history'
          })
        }
      }
    ]
    return tabs
  }

  get drawerMode () {
    if (this.dependencyOrigin || this.dependencyTarget) {
      return 'dependency'
    } else if (this.currentObjects.length > 1) {
      return 'multiple'
    } else if (this.currentObject) {
      return this.currentObject.name.includes('\n') ? 'details-two-line' : 'details-one-line'
    }
  }

  get drawerWidth () {
    return this.drawerMode && this.loaded ? 340 : 0
  }

  get showObjectTab () {
    return this.currentObjectIds.length === 1 && !!this.currentObject
  }

  get loaded () {
    return this.dataLoaded && this.resourceLoaded && this.focusedObject
  }

  get resourceLoaded () {
    return this.editorModule.resourceLoaded
  }

  get dataLoaded () {
    const connectionsSubscriptionStatus = this.modelModule.connectionsSubscriptionStatus
    const diagramsSubscriptionStatus = this.diagramModule.diagramsSubscriptionStatus
    const domainsSubscriptionStatus = this.domainModule.domainsSubscriptionStatus
    const flowsSubscriptionStatus = this.flowModule.flowsSubscriptionStatus
    const objectsSubscriptionStatus = this.modelModule.objectsSubscriptionStatus
    const tagGroupsSubscriptionStatus = this.tagModule.tagGroupsSubscriptionStatus
    const tagsSubscriptionStatus = this.tagModule.tagsSubscriptionStatus

    return !!this.currentShareLink || (
      this.organizationModule.organizationsListStatus.success &&
      this.organizationModule.organizationUsersListStatus.successInfo.organizationId === this.currentOrganizationId &&

      this.teamModule.teamsListStatus.success &&
      this.teamModule.teamsListStatus.successInfo.organizationId === this.currentOrganizationId &&

      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId || !!this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) &&
      (this.versionModule.versionsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId || !!this.versionModule.versionsSubscriptionStatus.loadingInfo.reconnect) &&

      ((connectionsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && connectionsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!connectionsSubscriptionStatus.loadingInfo.reconnect) &&
      ((diagramsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && diagramsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!diagramsSubscriptionStatus.loadingInfo.reconnect) &&
      ((domainsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && domainsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!domainsSubscriptionStatus.loadingInfo.reconnect) &&
      ((flowsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && flowsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!flowsSubscriptionStatus.loadingInfo.reconnect) &&
      ((objectsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && objectsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!objectsSubscriptionStatus.loadingInfo.reconnect) &&
      ((tagGroupsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && tagGroupsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!tagGroupsSubscriptionStatus.loadingInfo.reconnect) &&
      ((tagsSubscriptionStatus.successInfo.landscapeId === this.currentLandscapeId && tagsSubscriptionStatus.successInfo.versionId === this.currentVersionId) || !!tagsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get objectFamilyIds () {
    const objectFamilyIds: Record<string, string[]> = {}
    Object.values(this.modelModule.connections).forEach(o => {
      objectFamilyIds[o.originId] = [o.originId, ...this.modelModule.objects[o.originId]?.parentIds ?? []].filter(o => this.modelModule.objects[o]?.type !== 'root')
      objectFamilyIds[o.targetId] = [o.targetId, ...this.modelModule.objects[o.targetId]?.parentIds ?? []].filter(o => this.modelModule.objects[o]?.type !== 'root')
    })
    return objectFamilyIds
  }

  get directIncomingConnections () {
    return this.focusedObject ? directIncomingConnections(this.focusedObject.id, this.modelModule.connections) : {}
  }

  get directOutgoingConnections () {
    return this.focusedObject ? directOutgoingConnections(this.focusedObject.id, this.modelModule.connections) : {}
  }

  get lowerIncomingConnections () {
    return this.focusedObject ? lowerIncomingConnections(this.focusedObject, this.objectFamilyIds, this.modelModule.connections) : {}
  }

  get lowerOutgoingConnections () {
    return this.focusedObject ? lowerOutgoingConnections(this.focusedObject, this.objectFamilyIds, this.modelModule.connections) : {}
  }

  get incomingConnections () {
    const incomingConnections: Record<string, { lower: string[], direct: string[] }> = {}
    Object.entries(this.directIncomingConnections).forEach(([key, val]) => {
      if (!incomingConnections[key]) {
        incomingConnections[key] = {
          direct: [],
          lower: []
        }
      }
      incomingConnections[key].direct.push(...val)
    })
    Object.entries(this.lowerIncomingConnections).forEach(([key, val]) => {
      if (!incomingConnections[key]) {
        incomingConnections[key] = {
          direct: [],
          lower: []
        }
      }
      incomingConnections[key].lower.push(...val)
    })
    return incomingConnections
  }

  get outgoingConnections () {
    const outgoingConnections: Record<string, { lower: string[], direct: string[] }> = {}
    Object.entries(this.directOutgoingConnections).forEach(([key, val]) => {
      if (!outgoingConnections[key]) {
        outgoingConnections[key] = {
          direct: [],
          lower: []
        }
      }
      outgoingConnections[key].direct.push(...val)
    })
    Object.entries(this.lowerOutgoingConnections).forEach(([key, val]) => {
      if (!outgoingConnections[key]) {
        outgoingConnections[key] = {
          direct: [],
          lower: []
        }
      }
      outgoingConnections[key].lower.push(...val)
    })
    return outgoingConnections
  }

  get incomingConnectionIds () {
    return [
      ...Object.values(this.directIncomingConnections),
      ...Object.values(this.lowerIncomingConnections)
    ].flat()
  }

  get outgoingConnectionIds () {
    return [
      ...Object.values(this.directOutgoingConnections),
      ...Object.values(this.lowerOutgoingConnections)
    ].flat()
  }

  get incomingConnectionObjectIds () {
    return [...new Set([
      ...Object.keys(this.directIncomingConnections),
      ...Object.keys(this.lowerIncomingConnections)
    ].map(o => o.split('-')[0]))]
  }

  get outgoingConnectionObjectIds () {
    return [...new Set([
      ...Object.keys(this.directOutgoingConnections),
      ...Object.keys(this.lowerOutgoingConnections)
    ].map(o => o.split('-')[1]))]
  }

  get incomingObjects () {
    return this.incomingConnectionObjectIds.map(o => this.modelModule.objects[o]).filter(o => o)
  }

  get outgoingObjects () {
    return this.outgoingConnectionObjectIds.map(o => this.modelModule.objects[o]).filter(o => o)
  }

  get filteredIncomingObjects () {
    if (this.searchIncoming) {
      const fuzzy = new Fuse(this.incomingObjects, {
        keys: [
          'name',
          'caption',
          'type',
          'status'
        ],
        threshold: 0.3
      })
      return fuzzy.search(this.searchIncoming).map(o => o.item)
    } else {
      return this.incomingObjects
    }
  }

  get filteredOutgoingObjects () {
    if (this.searchOutgoing) {
      const fuzzy = new Fuse(this.outgoingObjects, {
        keys: [
          'name',
          'caption',
          'type',
          'status'
        ],
        threshold: 0.3
      })
      return fuzzy.search(this.searchOutgoing).map(o => o.item)
    } else {
      return this.outgoingObjects
    }
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId?: string) {
    if (!this.currentShareLink && currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.organizationModule.organizationUsersList(currentOrganizationId)
    }
    if (!this.currentShareLink && currentOrganizationId && this.teamModule.teamsListStatus.loadingInfo.organizationId !== currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.teamModule.teamsList(currentOrganizationId)
    }
  }

  @Watch('currentDomainHandleId')
  onCurrentDomainHandleIdChanged () {
    if (this.dataLoaded && !this.focusedObject) {
      const defaultObject = this.getDefaultObject()
      this.focusObject(defaultObject?.handleId)
    }
  }

  @Watch('objectFocusedHandleId')
  async onFocusedObjectHandleIdChanged (objectFocusedHandleId?: string) {
    if (this.dataLoaded && !this.focusedObject) {
      const defaultObject = this.getDefaultObject()
      await this.focusObject(defaultObject?.handleId)
    }

    if (objectFocusedHandleId) {
      sessionStorage.setItem(FOCUSED_OBJECT_KEY, objectFocusedHandleId)
    } else {
      sessionStorage.removeItem(FOCUSED_OBJECT_KEY)
    }

    if (this.currentLandscape && this.focusedObject) {
      analytics.modelDependenciesScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectType: this.focusedObject.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  @Watch('currentLandscape')
  onCurrentLandscapeChanged (currentLandscape?: Landscape, prevCurrentLandscape?: Landscape) {
    if (currentLandscape && currentLandscape?.id !== prevCurrentLandscape?.id && this.focusedObject) {
      analytics.modelDependenciesScreen.track(this, {
        landscapeId: [currentLandscape.id],
        modelObjectType: this.focusedObject.type,
        organizationId: [currentLandscape.organizationId]
      })
    }
  }

  @Watch('dataLoaded')
  onDataLoadedChanged (dataLoaded: boolean, prevDataLoaded: boolean) {
    if (dataLoaded && !prevDataLoaded) {
      this.onDataLoaded()
    }
  }

  @Watch('$route')
  onRouteChange () {
    const query: any = {}

    if (this.objectTab && !this.objectTabs.some(o => o.id === this.objectTab)) {
      query.object_tab = this.objectTabs[0].id
    }

    this.$replaceQuery(query)
  }

  @Watch('showObjectTab')
  onShowObjectTabChanged (newVal?: boolean, oldVal?: boolean) {
    if (newVal !== oldVal) {
      setImmediate(() => {
        const query: any = {}

        if (newVal && !this.objectTab) {
          query.object_tab = this.objectTabs[0].id
        } else if (!newVal && this.objectTab) {
          query.object_tab = undefined
        }

        this.$replaceQuery(query)
      })
    }
  }

  mounted () {
    if (!this.editorModule.resourceLoaded) {
      this.editorModule.loadResources()
    }

    if (!this.currentShareLink && this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUsersList(this.currentOrganizationId)
    }
    if (!this.currentShareLink && this.currentOrganizationId && this.teamModule.teamsListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.teamModule.teamsListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.teamModule.teamsList(this.currentOrganizationId)
    }

    if (this.dataLoaded) {
      this.onDataLoaded()
    }

    this.keydownListener = this.keydown.bind(this)
    window.addEventListener('keydown', this.keydownListener)

    if (this.currentLandscape && this.focusedObject) {
      analytics.modelDependenciesScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        modelObjectType: this.focusedObject.type,
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  onDataLoaded () {
    const defaultObject = this.getDefaultObject()
    this.focusObject(this.focusedObject?.handleId ?? defaultObject?.handleId, this.objectTab ?? undefined)
  }

  destroyed () {
    window.removeEventListener('keydown', this.keydownListener)
  }

  setSearchIncomingDebounce = debounce(this.setSearchIncoming.bind(this), 300)

  setSearchIncoming (search: string) {
    this.searchIncoming = search

    analytics.modelDependenciesSearch.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelDependenciesSearchQuery: search,
      modelDependenciesSearchType: 'incoming',
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  setSearchOutgoingDebounce = debounce(this.setSearchOutgoing.bind(this), 300)

  setSearchOutgoing (search: string) {
    this.searchOutgoing = search

    analytics.modelDependenciesSearch.track(this, {
      landscapeId: [this.currentLandscape.id],
      modelDependenciesSearchQuery: search,
      modelDependenciesSearchType: 'outgoing',
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  getDefaultObject () {
    const lastFocusedObjectId = sessionStorage.getItem(FOCUSED_OBJECT_KEY)
    const lastFocusedObject = this.currentDomainObjects.find(o => o.handleId === lastFocusedObjectId)

    const coreInternalSystem = this.currentDomainObjects
      .filter(o => o.type === 'system' && [o.id, ...o.parentIds].some(id => this.modelModule.objects[id]?.external === false))
      .sort((a, b) => Object.keys(a.diagrams).length + Object.keys(a.flows).length > Object.keys(b.diagrams).length + Object.keys(b.flows).length ? -1 : 1)
      .find(o => o)

    const coreInternalObject = this.currentDomainObjects
      .filter(o => o.type !== 'system' && [o.id, ...o.parentIds].some(id => this.modelModule.objects[id]?.external === false))
      .sort((a, b) => Object.keys(a.diagrams).length + Object.keys(a.flows).length > Object.keys(b.diagrams).length + Object.keys(b.flows).length ? -1 : 1)
      .find(o => o)

    const coreExternalSystem = this.currentDomainObjects
      .filter(o => o.type === 'system' && [o.id, ...o.parentIds].some(id => this.modelModule.objects[id]?.external === true))
      .sort((a, b) => Object.keys(a.diagrams).length + Object.keys(a.flows).length > Object.keys(b.diagrams).length + Object.keys(b.flows).length ? -1 : 1)
      .find(o => o)

    const coreExternalObject = this.currentDomainObjects
      .filter(o => o.type !== 'system' && [o.id, ...o.parentIds].some(id => this.modelModule.objects[id]?.external === true))
      .sort((a, b) => Object.keys(a.diagrams).length + Object.keys(a.flows).length > Object.keys(b.diagrams).length + Object.keys(b.flows).length ? -1 : 1)
      .find(o => o)

    return lastFocusedObject ?? coreInternalSystem ?? coreInternalObject ?? coreExternalSystem ?? coreExternalObject
  }

  keydown (event: KeyboardEvent) {
    const metaKey = window.navigator.platform.toLowerCase().includes('mac') ? event.metaKey : event.ctrlKey
    if (event.key.toLowerCase() === 'f' && metaKey) {
      this.objectSelectMenuRef.open()
      event.preventDefault()
    } else if (event.code === 'Escape') {
      this.objectSelectMenuRef.close()
      event.preventDefault()
    }
  }

  areModelObjectsProtected (...modelObjectsIds: (string | undefined)[]): boolean {
    if (this.currentLandscapePermission === 'admin') { return false }
    return modelObjectsIds
      .filter((o): o is string => !!o)
      .some(o => {
        const modelObject = this.modelModule.objects[o]
        return (
          modelObject &&
          modelObject.teamOnlyEditing &&
          !!modelObject.teamIds.length &&
          !this.teamModule.userTeams.some(o => modelObject.teamIds.includes(o.id))
        )
      })
  }

  editNotificationStart (id: string) {
    const landscapeId = this.currentLandscape?.id
    if (landscapeId) {
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: {
          id
        }
      })
      clearInterval(this.editingNotificationTimer)
      this.editingNotificationTimer = window.setInterval(() => {
        this.editorModule.editorTypingUpdate({
          landscapeId,
          typing: {
            id
          }
        })
      }, EDITING_INTERVAL)
    }
  }

  editNotificationEnd () {
    const landscapeId = this.currentLandscape?.id
    if (landscapeId) {
      clearInterval(this.editingNotificationTimer)
      this.editorModule.editorTypingUpdate({
        landscapeId,
        typing: {}
      })
    }
  }

  objectLinksSync () {
    if (this.currentObject) {
      this.modelModule.objectLinksSync({
        landscapeId: this.currentLandscapeId,
        objectId: this.currentObject.id,
        versionId: this.currentObject.versionId
      })
    }
  }

  async focusObject (handleId?: string, objectTab?: string) {
    if (!handleId) { return }

    await this.$pushQuery({
      dependency_origin: undefined,
      dependency_target: undefined,
      expanded_connection: undefined,
      expanded_connection_tab: undefined,
      object: undefined,
      object_focus: handleId,
      object_tab: objectTab
    })
  }
}
